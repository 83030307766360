
import { Box, Button, Container, Grid, Paper, TextField, Typography } from '@mui/material';
import React from 'react';
import EmptyHome from './common/EmptyHome';

const SignIn = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  return (
    <Container maxWidth="xs">
      <Box mt={4}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Typography variant="h4">
          </Typography>
          <Grid>
            <Paper elevation={1}>
              <Box mt={4} mb={2}>
              </Box>

              <Box mt={8} ml={4} mr={4} mb={8}>
                <div>
                  <Box mt={4} ml={4} mr={4} mb={8}>
                    <img src="welcome.svg" alt="Login" height="300" />
                  </Box>
                  <form noValidate>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                    />
                    <Box mt={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      href='/login'
                    >
                      Sign In
                    </Button>
                    </Box>
                  </form>
                </div>
              </Box>
              <Box mb={4}>
                &nbsp;
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default SignIn;
