import { Chip, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const CropPredictionAndTopSelling = () => {
    return (
        <Box>
            <Box mt={2} mb={2}>
                <Typography variant="h6">
                    Crop Perdiction
                    <Divider fullWidth/>
                </Typography>
                <Typography variant="body1" mt={2}> <img src="tomato.png" alt="tomato"/> Tomato (Seed Selling Season) </Typography> 
                <Typography variant="body1"> <img src="eggplant.png" alt="eggplant" /> Brinjal (Harvest Season)</Typography>
            </Box>
            <Box mt={2} mb={2}>
                <Typography variant="h6">
                    Top Selling Products
                    <Divider fullWidth/>
                </Typography>
                <Typography variant="body1" mt={2}> 
                <Chip label="SAAHO" /><Chip label="US 440" /><Chip label="ABHILASH" color="secondary" /><Chip label="HEEMSOHNA" />
                <Chip label="SHIVAM" /><Chip label="ABHINAV" /><Chip label="NS 2535" /><Chip label="HARD ROCK" />
                </Typography>
            </Box>
        </Box>
    )
}

export default CropPredictionAndTopSelling;
