import { createTheme } from "@mui/material";

export const customTheme = () => {
    return createTheme({
        components: {
            MuiToolbar: {
                styleOverrides: {
                    dense: {
                        height: 50,
                        minHeight: 50
                    }
                }
            }
        },    
        typography: {
           },        
        palette: {
          primary: {
            main: "#395144",
            light: '#395144'
          },
          secondary: {
            main: "#F0FF42",
            light: '#F0FF42'
          },
        },
      });
}

