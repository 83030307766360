import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const EmptyHome = () => {
  return (
    <Grid container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: '80vh' }}
  >
      <Box mt={8} />
      <img src="login.jpg" alt="welcome" width="50%"/>
      
      <Box mt={4} mb={2}>
          <Typography variant="h4">
              Welcome to Agri Saraswati
          </Typography>
      </Box>
      
      <Button variant="contained">
        <Link to="/predict" style={{ textDecoration: 'none', color: 'white' }}>
            Get Started
        </Link>
      </Button>
    </Grid>
  );
};

export default EmptyHome;
