import React from "react"

import { GOOGLE_API_KEY } from "../../EnvVars";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { Box } from "@mui/system";

const GoogleMaps = () => {

    const MyMapComponent = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
      defaultZoom={10}
      defaultCenter={{ lat: 13.132663433165462, lng: 78.12667597764862 }}
    >
      {props.isMarkerShown && <Marker position={{ lat: 13.132663433165462, lng: 78.12667597764862 }} icon="tomato.png" />}
      {props.isMarkerShown && <Marker position={{ lat: 12.992387012597877, lng: 78.17685446198834 }} icon="tomato.png" />}
      {props.isMarkerShown && <Marker position={{ lat: 13.002912370842187, lng: 77.93924369035769 }} icon="tomato.png" />}
      {props.isMarkerShown && <Marker position={{ lat: 13.333025315075426, lng: 78.20908424263064 }} icon="tomato.png" />}
      {props.isMarkerShown && <Marker position={{ lat: 13.161220528201584, lng: 78.39275209319455 }} icon="eggplant.png" />}
    </GoogleMap>
  ));
  
      const googleMapUrlDetails = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`;
  
  
    return(
        <Box mt={2}>
        <MyMapComponent
            isMarkerShown
            googleMapURL={googleMapUrlDetails}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `600px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
        />
    </Box>
    )
}

export default GoogleMaps;
