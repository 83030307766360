import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const CropVarityAndCalendar = () => {
    return (
        <Box>
            <Box mt={2} mb={2}>
                <Typography variant="h6">
                    Crop Varity
                    <Divider fullWidth/>
                </Typography>
                <Typography variant="body1">
                    <a href="https://www.bighaat.com/collections/tomato-seeds" alt="varity">https://www.bighaat.com/collections/tomato-seeds</a>                        
                </Typography>
            </Box>

            <Box>
            <Typography variant="h6">
                    Crop Calendar
                    <Divider fullWidth/>
                    <img src="crop-cal.png" alt="crop calendar" />
            </Typography>
            </Box>
        </Box>
    )
}

export default CropVarityAndCalendar;
