import { Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react"

const CropDetails = () => {
    return(
        <Box mt={2} mb={2}>
                    <Typography variant="h6">
                            Details of the Crop
                            <Divider fullWidth/>
                        </Typography>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>Details</TableCell>
                                <TableCell>Description</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>                            
                                <TableRow key="1">
                                    <TableCell>Crop</TableCell>
                                    <TableCell>Tomato</TableCell>
                                </TableRow>
                                <TableRow key="2">
                                    <TableCell>Scientific Name </TableCell>
                                    <TableCell>Lycopersicum  esculentum</TableCell>
                                </TableRow>
                                <TableRow key="3">
                                    <TableCell>Common Name</TableCell>
                                    <TableCell>Tomato , Tomatar , Tamata</TableCell>
                                </TableRow>
                                <TableRow key="4">
                                    <TableCell>Season </TableCell>
                                    <TableCell>Tomato can be grown through out the year. 
                                        Rainly Season : May-July
                                        WinterSpring/  : Aug-Sept / Dec- Jan
                                        Summer : Feb - March</TableCell>
                                </TableRow>
                                <TableRow key="5">
                                    <TableCell>Climate</TableCell>
                                    <TableCell>Warm season crop, highly susceptible to frost. 
                                        Dry wind results in dropping of blossoms. 
                                        Average monthly temperature 20 - 25 C.
                                    </TableCell>
                                </TableRow>
                                <TableRow key="6">
                                    <TableCell>Temperature</TableCell>
                                    <TableCell> High temperature above 38 C - 40 C adversely affects the fruit set & development
                                    </TableCell>
                                </TableRow>
                                <TableRow key="7">
                                    <TableCell>Soil</TableCell>
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </Box>        
    )
}

export default CropDetails;

