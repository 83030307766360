import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const Selection = () => {

    const [stateValue, setStateValue] = React.useState("Karnataka");

    return (
<Box mt={2} mb={2}>
    <FormControl fullWidth>
        <InputLabel id="state">Month & Year</InputLabel>
        <Select
        labelId="month"
        id="month"
        value="December 2022"
        label="Month"
        >
        <MenuItem value="December 2022">December 2022</MenuItem>
        <MenuItem value="January 2023">January 2023</MenuItem>
        <MenuItem value="February 2023">February 2023</MenuItem>
        </Select>
    </FormControl>

    <Box mt={2} />
    <FormControl fullWidth>
        <InputLabel id="state">State</InputLabel>
        <Select
        labelId="state"
        id="state"
        value={stateValue}
        label="State"
        onChange={(e) => setStateValue(e.target.value)}
        >
        <MenuItem value="Andhra Pradesh">Andhra Pradesh</MenuItem>
        <MenuItem value="Karnataka">Karnataka</MenuItem>
        </Select>
    </FormControl> 
    
    <Box mt={2} />
    <FormControl fullWidth>
        <InputLabel id="district">District</InputLabel>
        <Select
        labelId="district"
        id="district"
        value="Kolar"
        label="District"
        >
        <MenuItem value="Bangalore">Bangalore</MenuItem>
        <MenuItem value="Kolar">Kolar</MenuItem>
        <MenuItem value="Malur">Malur</MenuItem>
        <MenuItem value="H.Golahalli">H.Golahalli</MenuItem>                                
        <MenuItem value="Chickballapur">Chickballapur</MenuItem>
        <MenuItem value="Mulbagal">Mulbagal</MenuItem>
        </Select>
    </FormControl> 

</Box>
    );
}

export default Selection;