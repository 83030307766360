import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const ClimatePrediction = () => {
    return (
        <Box>
        <Typography variant="h6">
                Climate Prediction
                <Divider fullWidth/>
        </Typography>
        <iframe title="climateprediction" src="https://www.theweather.com/getwid/8392e5d86359ea0b2fe2f4f114e3f8c4" width="100%" height="350" frameborder="0" seamless></iframe>                    
        </Box>
    )
}

export default ClimatePrediction;

