import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Selection from "./Selection";
import GoogleMaps from "./GoogleMaps";
import CropDetails from "./CropDetails";
import CropPredictionAndTopSelling from "./CropPredictionAndTopSelling";
import CropVarityAndCalendar from "./CropVarityAndCalendar";
import ClimatePrediction from "./ClimatePrediction";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
    

const Summary = () => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return(
        <Grid container>

            <Box sx={{ width: '100%' }} mt={8} ml={8} mr={8} mb={2}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Crop Predictions"  />
                    <Tab label="Sales Projections"  />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                <Grid container>
                <Grid item xs={6}>
            <Box>
                <Box mt={2}>
                    <Selection />                    
                    <CropPredictionAndTopSelling />
                    <CropVarityAndCalendar />
                    <ClimatePrediction />
                </Box>

        </Box>            
        </Grid>
            <Grid item xs={6}>
                <Box ml={1}>
                    
                    <GoogleMaps />
                    <CropDetails />
                </Box>            
            </Grid>
            </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                <iframe src="http://localhost:8000/" width="100%" height="800" frameborder="0" seamless title="projection"></iframe>                                    
                </TabPanel>
                </Box>            
        </Grid>
    );
}

export default Summary;
