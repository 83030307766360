import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { DashboardRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';

const Header = () => {

  return (
    <AppBar>
      <Grid container>
      <Container maxWidth="xm">
        <Toolbar disableGutters variant='dense'>
        <Grid item xs={9}>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>                     
          <Typography
            variant="h6"
            noWrap
            sx={{
              mr: 2,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >            
          Agri Saraswati
          </Typography>
          </Box>
          </Grid>
          <Grid item xs={3} container justifyContent="flex-end">
            <Button color="secondary" variant="outlined" href="/">Logout</Button>
          </Grid>
        </Toolbar>
      </Container>
      </Grid>
    </AppBar>
  );
};
export default Header;
